import React from 'react';
import { render } from 'react-dom';

import LoadingScreen from 'app/shared/components/loading-screen';
import AsyncLoad from 'app/shared/helpers/async-load';

import reportWebVitals from './reportWebVitals';

// @ts-ignore
// import { setDefaultCredentials } from '@deck.gl/carto';

// import { initialState } from 'app/core/domain/mapInitialStateSlice';

//Styles
import './global.css';

const Core = AsyncLoad(
  () => import(/* webpackChunkName: "Core" */ 'app/core'),
  LoadingScreen,
);

// setDefaultCredentials({ ...initialState.credentials });

render(
  <React.StrictMode>
    <Core />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
