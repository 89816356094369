import loadable, { DefaultComponent } from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { timeout } from 'promise-timeout';
import React from 'react';

const AsyncLoad = (
  loader: <Props>() => Promise<DefaultComponent<Props>>,
  Fallback?: () => React.JSX.Element,
) =>
  loadable(() => pMinDelay(timeout(loader(), 10000), 200), {
    fallback: Fallback ? (
      <Fallback />
    ) : (
      <span data-testid="spinner">Spinner</span>
    ),
  });

export default AsyncLoad;
